import { __awaiter, __generator, __spreadArray } from "tslib";
import { Terminal } from "@xterm/xterm";
import { WebglAddon } from "@xterm/addon-webgl";
import { FitAddon } from "@xterm/addon-fit";
import chalk from "chalk";
import "@xterm/xterm/lib/xterm.js";
import "@xterm/xterm/css/xterm.css";
var term = new Terminal({
    theme: {
        foreground: "#F8F8F8",
        background: "#2D2E2C",
        //selection: "#5DA5D533",
        black: "#1E1E1D",
        brightBlack: "#262625",
        red: "#CE5C5C",
        brightRed: "#FF7272",
        green: "#5BCC5B",
        brightGreen: "#72FF72",
        yellow: "#CCCC5B",
        brightYellow: "#FFFF72",
        blue: "#5D5DD3",
        brightBlue: "#7279FF",
        magenta: "#BC5ED1",
        brightMagenta: "#E572FF",
        cyan: "#5DA5D5",
        brightCyan: "#72F0FF",
        white: "#F8F8F8",
        brightWhite: "#FFFFFF"
    },
    cursorBlink: true,
    allowProposedApi: true
});
var fitAddon = new FitAddon();
term.loadAddon(fitAddon);
term.open(document.body);
fitAddon.fit();
try {
    var webgl = new WebglAddon();
    term.loadAddon(webgl);
}
catch (e) {
    console.warn("WebGL addon threw an exception during load", e);
}
var data = {};
function createSystem(name) {
    return {
        name: name,
        sessions: [],
        constraints: [],
        addSession: function (start, end) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loadData()];
                        case 1:
                            _a.sent();
                            this.sessions.push({
                                start: start,
                                end: end
                            });
                            return [4 /*yield*/, saveData()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        addConstraint: function (f) {
            this.constraints.push(f);
        },
        evaluateConstraints: function () {
            var _this = this;
            return this.constraints.map(function (c) { return ({ name: c.name, result: c.eval(_this.sessions) }); });
        }
    };
}
var systems = {
    eat: createSystem("eat"),
    exercise: createSystem("exercise"),
    sleep: createSystem("sleep"),
    work: createSystem("work")
};
function saveData() {
    return __awaiter(this, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = JSON.stringify(Object.fromEntries(Object.entries(systems).map(function (_a) {
                        var k = _a[0], v = _a[1];
                        return [k, v.sessions];
                    })));
                    return [4 /*yield*/, fetch("https://disjecta-memory.r-om.workers.dev", {
                            method: "POST",
                            body: body
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function loadData() {
    return __awaiter(this, void 0, void 0, function () {
        var system;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("https://disjecta-memory.r-om.workers.dev").then(function (r) { return r.json(); })];
                case 1:
                    data = _a.sent();
                    if (typeof data !== "object" || data === null)
                        return [2 /*return*/];
                    for (system in data) {
                        if (!(system in systems))
                            continue;
                        systems[system].sessions = data[system];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
loadData();
function need(f) {
    var t = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        t[_i - 1] = arguments[_i];
    }
    return function (s) { return f.apply(void 0, __spreadArray([s], t.map(function (k) { return systems[k].sessions; }), false)); };
}
// the constraints with dates can kind of be linear if we record when the new constraint system starts
systems.sleep.addConstraint({
    name: "up before 9am",
    eval: function (sessions) { return sessions.some(function (s) { return new Date(s.end).getHours() < 9; }); }
});
systems.exercise.addConstraint({
    name: "totals at least one hour",
    eval: function (sessions) { return sessions.reduce(function (a, b) { return a + (b.end - b.start); }, 0) >= 60 * 60 * 1000; }
});
var meal = function (name, hours, label) {
    if (label === void 0) { label = "(h + ".concat(hours, ")"); }
    systems.eat.addConstraint({
        name: "".concat(name, " ").concat(label),
        eval: need(function (eat, sleep) {
            if (sleep[0] === undefined)
                return false;
            var hour = new Date(sleep[0].end + hours * 60 * 60 * 1000).getHours();
            return eat.some(function (s) { return new Date(s.start).getHours() === hour; });
        }, "sleep")
    });
};
meal("morrowtide meal", 0.333, "(h + (m > 40))");
meal("undertide meal", 2.5);
meal("noontide meal", 5);
meal("eventide meal", 7.5);
meal("vespertide meal", 10);
var lat = 0, lon = 0;
navigator.geolocation.getCurrentPosition(function (pos) {
    lat = pos.coords.latitude;
    lon = pos.coords.longitude;
}, function () { }, { enableHighAccuracy: false });
var sunset = 0;
var bedtimeConstraint = {
    name: "go to bed before an hour after sunset",
    eval: function (sessions) {
        if (sessions[1] === undefined)
            return false;
        return sessions[1].end < sunset + 60 * 60 * 1000;
    }
};
systems.sleep.addConstraint(bedtimeConstraint);
function calcSunset() {
    return __awaiter(this, void 0, void 0, function () {
        var res, date, min;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("https://api.sunrise-sunset.org/json?lat=".concat(lat, "&lng=").concat(lon, "&formatted=0")).then(function (res) { return res.json(); })];
                case 1:
                    res = _a.sent();
                    date = new Date(res.results.civil_twilight_end);
                    sunset = date.getTime();
                    min = date.getMinutes();
                    bedtimeConstraint.name = "go to bed before ".concat(date.getHours(), ":").concat(min >= 10 ? min : "0" + min);
                    return [2 /*return*/];
            }
        });
    });
}
var command = "";
function prompt() {
    command = "";
    term.write("$ ");
}
term.onData(function (e) {
    switch (e) {
        case "\u0003": // Ctrl+C
            term.write("^C");
            prompt();
            break;
        case "\r": // Enter
            runCommand();
            command = "";
            break;
        case "\u007F": // Backspace (DEL)
            // Do not delete the prompt
            if (term.buffer.active.cursorX > 2) {
                term.write("\b \b");
                if (command.length > 0) {
                    command = command.slice(0, command.length - 1);
                }
            }
            break;
        default:
            if ((e >= String.fromCharCode(0x20) && e <= String.fromCharCode(0x7e)) || e >= "\u00a0") {
                command += e;
                term.write(e);
            }
    }
});
var currentSystem = null;
function getTime(t) {
    var _a = t.split(":").map(function (x) { return parseInt(x); }), h = _a[0], m = _a[1];
    if (isNaN(h) || isNaN(m)) {
        return null;
    }
    var d = new Date();
    d.setHours(h, m, 0, 0);
    return d;
}
function runCommand() {
    return __awaiter(this, void 0, void 0, function () {
        var systemsRecord, cmd, output, _i, _a, system, _b, _c, c, _d, _e, system, s, start, end, startD, endD;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    systemsRecord = systems;
                    cmd = command.trim().split(" ");
                    term.writeln("");
                    if (!(cmd[0] === "st")) return [3 /*break*/, 2];
                    output = [];
                    if (currentSystem !== null) {
                        output.push("you're currently in system ".concat(currentSystem.name));
                    }
                    output.push("systems:");
                    return [4 /*yield*/, Promise.all([calcSunset(), loadData()])];
                case 1:
                    _f.sent();
                    for (_i = 0, _a = Object.values(systems); _i < _a.length; _i++) {
                        system = _a[_i];
                        output.push("".concat(system.name, " (").concat(system.constraints.length, " constraints)"));
                        for (_b = 0, _c = system.evaluateConstraints(); _b < _c.length; _b++) {
                            c = _c[_b];
                            output.push("  ".concat(c.name, ": ").concat(c.result ? chalk.green("pass") : chalk.red("fail")));
                        }
                    }
                    term.writeln(output.join("\n\r"));
                    return [3 /*break*/, 4];
                case 2:
                    if (!(cmd[0] === "cl")) return [3 /*break*/, 4];
                    for (_d = 0, _e = Object.values(systems); _d < _e.length; _d++) {
                        system = _e[_d];
                        system.sessions = [];
                    }
                    return [4 /*yield*/, saveData()];
                case 3:
                    _f.sent();
                    term.writeln("cleared data");
                    _f.label = 4;
                case 4:
                    s = systemsRecord[cmd[0]];
                    if (!(s !== undefined)) return [3 /*break*/, 9];
                    start = cmd[1];
                    end = cmd[2];
                    if (!(start !== undefined && end !== undefined)) return [3 /*break*/, 6];
                    startD = getTime(start);
                    endD = getTime(end);
                    if (startD === null || endD === null) {
                        term.writeln("invalid time");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, s.addSession(startD.getTime(), endD.getTime())];
                case 5:
                    _f.sent();
                    term.writeln("added session");
                    return [3 /*break*/, 9];
                case 6:
                    if (!(currentSystem !== null)) return [3 /*break*/, 8];
                    term.writeln("finishing up ".concat(currentSystem.name));
                    return [4 /*yield*/, systemsRecord[currentSystem.name].addSession(currentSystem.start, Date.now())];
                case 7:
                    _f.sent();
                    _f.label = 8;
                case 8:
                    currentSystem = {
                        name: s.name,
                        start: Date.now()
                    };
                    term.writeln("starting ".concat(currentSystem.name));
                    _f.label = 9;
                case 9:
                    prompt();
                    return [2 /*return*/];
            }
        });
    });
}
prompt();
